.box-hosting-wrapper{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.box-hosting-pack {
    position: relative;
    box-sizing: border-box;
    width: 180pt;
    height: 200pt;
    border: 1px solid #ccc;
    border-radius: 5pt;
    margin: 10pt;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}

.box-hosting-pack:hover {
    position: relative;
    box-sizing: border-box;
    width: 180pt;
    height: 200pt;
    border: 1px solid #09F;
    border-radius: 5pt;
    margin: 10pt;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}

.box-hosting-pack-title {
    background-color: #ccc;
    color: #fff;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}

.box-hosting-pack:hover .box-hosting-pack-title {
    background-color: #09F;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}
