.page-content-title {
    position: relative;
    display: inline-block;
    width: 100%;
    font-size: 24pt;
    font-weight: 600;
}

.page-content-detail {
    position: relative;
    display: inline-block;
    width: 100%;
    font-size: 12pt;
}
