.toggle-navbar-wrapper {
    display: none;
    top: 5.05rem;
    flex-direction: column;
    align-items: center;
    position: fixed;
    left: 0;
    right: 0;
    transition: 0.3s ease-in-out top;
    text-align: center;
    height: 100%;
    width: 100%;
    background-color: #000000;
    z-index: 5;
    padding-top: 3rem;

    .flag {
        display: flex !important;
    }
}

.toggle-navbar-open {
    display: flex;
}

.toggle-navbar-item {
    display: block;
    padding: 0.5rem;
}

.toggle-flag-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;

    .flag {
        margin-right: 1rem !important;
        margin-left: 1rem !important;
    }
}
