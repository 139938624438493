.menu-top {
    position: fixed;
    width: 100%;
    height: 3rem;
    color: #fff;
    z-index: 4;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    padding: 1rem 0;
    background-color: unset;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}

.menu-top-fixed {
    background-color: #ffffff;
    color: #000000 !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .toggle-navbar-wrapper {
        background-color: #FFFFFF;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        color: #000000 !important;
    }
}

.menu-top-links {
    font-weight: bold;
    text-decoration: none;
}

.menu-top-container {
    margin: 0 auto;
    flex: auto;
    display: flex;
    justify-content: end;
}

.menu-top-item-left {
    position: relative;
    padding: 0 0 20pt;
}

.menu-top-item-right {
    position: relative;
    padding: 0 3rem 0 0;
}

.logo-kirilmaz {
    height: 30pt;
    width: auto;
    border: 0;
    cursor: pointer;
    z-index: 5;
    margin-left: 2rem;
}

.kirilmaz-services {
    display: none;
    position: absolute;
    width: 142pt;
    left: 20pt;
    top: 60pt;
    border-radius: 5pt;
    opacity: 0.5;
    background-color: #a6df21;
}

.link-services {
    color: #fff;
    text-decoration: none;
}

.menu-icon {
    display: none;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    margin-right: 2rem;
}

.page-start {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 80pt;
}

.menu-top-go-start {
    position: fixed;
    bottom: 2rem;
    left: 2rem;
    outline: none;
    cursor: pointer;
    border: none;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    z-index: 5;
    background-color: #000000;
    opacity: 0.6;
}

.menu-top-go-start-image {
    width: 100%;
    height: 100%;
}

.flag {
    width: 2rem;
    height: 2rem;
    background: none;
    border: none;
    margin-right: 2rem;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;

    img {
        width: 2rem;
        height: 2rem;
    }
}

.company-logo {
    padding-left: 2rem;
}
