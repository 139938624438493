.page-start-slider {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(0,0,0,.5);
}

.page-start-subject {
    position: absolute;
    display: inline-block;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0;
    transition: opacity ease-in-out 0.4s;
}

.active-subject {
    opacity: 1;
}

.page-start-subject-title {
    font-size: 30pt;
    font-weight: bold;
    margin-bottom: 2rem;
}

.page-start-subject-detail {
    font-size: 14pt;
    font-weight: 300;
    margin-bottom: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.page-start-subject-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: 50%;
    background-color: #000000;
}

.page-start-subject-bg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.4;
}

.page-start-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    //margin: 10% auto 0;
    color: #fff;
    text-align: center;
}

.page-start-title-wrapper {
    margin-top:auto;
    margin-bottom: auto;
    z-index: 2;
}

.page-start-link-detail {
    color: #fff;
    text-decoration: none;
}

.p-spacer {
    height: 10pt;
}

.button-apply {
    position: relative;
    display: inline-block;
    z-index: 2;
    cursor: pointer;
}

.button-apply-title {
    position: relative;
    display: inline-block;
    padding: 10pt 40pt;
}

.button-apply-bg {
    opacity: 0.3;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    position: absolute;
    left: 0;
    top: 0;
    display: inline-block;
    border: 1px solid #fff;
    border-radius: 20pt;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 2;
}

.button-apply-bg:hover {
    opacity: 0.6;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    position: absolute;
    left: 0;
    top: 0;
    display: inline-block;
    border: 1px solid #fff;
    background-color: #fff;
    border-radius: 20pt;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 2;
}

.page-start-navigate {
    position: absolute;
    top: 40%;
    right: 1%;
    display: inline-block;
    width: 30pt;
    z-index: 2;
}

.circle {
    opacity: 0.5;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    width: 16pt;
    height: 16pt;
    border-radius: 24pt;
    background-color: #fff;
    margin-bottom: 10pt;
    cursor: pointer;
}

.circle:hover {
    opacity: 1;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    width: 20px;
    height: 20px;
    border-radius: 32px;
    background-color: #fff;
    cursor: pointer;
}

.active-button {
    opacity: 1;
}

.page-start-navigate-content {
    position: relative;
    width: 30pt;
    height: 30pt;
}

.page-start-bg-filter {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #459d46;
    z-index: 2;
    opacity: 0.4;
}
