.page-content-part {
    position: relative;
    height: auto;
    z-index: 2;
    margin: 30pt auto 0;
}

.page-content-sub-title {
    position: relative;
    display: inline-block;
    width: 100%;
    font-size: 16pt;
    font-weight: 600;
}
