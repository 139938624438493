@media screen and (max-width: 1200px) {
    .menu-icon {
        display: block !important;
    }
    .menu-top-item-right {
        display: none;
    }
    .page-start-navigate {
        display: none !important;
    }
    .flag {
        display: none !important;
    }
    .toggle-navbar-wrapper {
        .flag {
            display: block !important;
        }
    }
}

@media screen and (max-width: 500px) {
    .page-start-subject-title {
        font-size: 20pt !important;
        margin-bottom: 1rem !important;
    }
    .page-content img {
        width: 100%;
    }
}
