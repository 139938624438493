.page {
    position: relative;
    width: 100%;
    margin-bottom: 10rem;
    text-align: center;
}

.page-content {
    position: relative;
    display: inline-block;
    height: auto;
    z-index: 2;
    margin: 80pt auto 0;
    text-align: center;
    max-width: 720pt;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}
