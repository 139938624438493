* {
    margin: 0;
    padding: 0;
}

html, body {
    width: 100%;
    height: 100%;
    font-family: 'Raleway', sans-serif;
    line-height: 2em;
    background-color: #FFFFFF;
}

a {
    text-decoration: none;
    color: unset;
}

#root {
    width: 100%;
    height: 100%;
    margin-top: 0;
    transition: 0.3s ease-in-out margin-top;
}

.full-width {
    width: 100%;
}

.mb-1 {
    margin-bottom: 1rem;
}

.mb-2 {
    margin-bottom: 2rem;
}

.bold {
    font-weight: 600;
}

.margin-left-auto {
    margin-left: auto;
}

ul {
    list-style-type: none;
}


